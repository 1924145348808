import React from "react";
import "../scss/tokenomics.scss"
import logo from "../image/logo-big.png";


    const Tokenomics = () => {
      return (
        <div className="tokenomics">
          <div className="tokenomics__box" id="tekonomiks">
            <div className="teks__name">Tokenomics</div>
            <div className="teks__descriprion">
              <div className="teks__descriprion__left">
                <div className="token__bokss">
                  <div className="soply__up">100B</div>
                  <div className="soply__down">Token Supply</div>
                </div>
                <div className="token__bokss">
                  <div className="soply__up">4%</div>
                  <div className="soply__down">Taxes</div>
                </div>
                <div className="token__bokss">
                  <div className="soply__up">0</div>
                  <div className="soply__down">Tokens Burned</div>
                </div>
                <div className="token__bokss">
                  <div className="soply__up">LP</div>
                  <div className="soply__down">Locked</div>
                </div>
                <div className="token__bokss">
                  <div className="soply__up">KVAR</div>
                  <div className="soply__down">Name</div>
                </div>
                <div className="token__bokss">
                  <div className="soply__up-c">#KVAR-C</div>
                  <div className="soply__up-c">#KVAR-I</div>
                  <div className="soply__up-c">#KVAR-J</div>
                  <div className="soply__down">Ticker</div>
                </div>
              </div>
              <div className="teks__descriprion__safu">
                
                  <img src={logo} alt="KVAR token logo" className="logo__big__logo"/>
                
                <div className="teks__descriprion__safu__text">
                  100% SAFU
                </div>               
              </div>
            </div>
            
          </div>
        </div>
      );
    };

    export default Tokenomics;
