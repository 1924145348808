import { createSlice } from '@reduxjs/toolkit'; 

const CurrencySlice = createSlice({
  name: 'religin',
  initialState: {
    status: [],
    side: false,
  },
  reducers: {
    stateNameReligion: (state, action) => {
      state.status = action.payload;
    },
    stateSideBar: (state, action) => {
      state.side = action.payload;
    },
  },
});

export const { stateNameReligion, stateSideBar } = CurrencySlice.actions;

export default CurrencySlice.reducer;
