import React from 'react';
import './footer.scss';
import { objTokens } from '../data/TokensData';

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer__box">
        <div className="footer__box__all">
          <div className="footer__logo__box">
            <div className="footer__logo"></div>
            <div className="logo__text__name">KVAR Indicator</div>
          </div>
          <div className="footer__info">
            <div className="footer__tok">Charts</div>
            {objTokens.map((item, index) => (
              <div className="footer__tok__t" key={index}>
                {item.bscscan ? (
                  <a href={item.bscscan}>{item.name} BscScan</a>
                ) : (
                  <div className="footer__tok__non">{item.name} BscScan</div>
                )}
              </div>
            ))}
          </div>
        </div>
        <div className="footer__email">kvar@kvartoken.com</div>
        <div className="footer__line"></div>
        <div className="footer__contact">
          <div className="contact__corp">
            Copyright © 2023 KVAR All Rights Reserved
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
