import React from "react";
import "../scss/roadmap.scss";
import torus from "../img_site/torus.png";
import rectangle from "../img_site/rectangle.png";
import rectangl from "../img_site/rectangle.png";

const RoadMap = () => {
  return (
    <div className="road__map" id="roadmap">
      <div className="road__box">
        <div className="road__name">Roadmap</div>
        <div className="road__phases">

          <div className="road__ph">
            <div className="phase__one">Phase 1</div>
            <div className="phase__text">
              <div className="phase__punkt">- Website launch</div>
              <div className="phase__punkt">- Listing Christian token - KVAR-C</div>
              <div className="phase__punkt">- Holders KVAR-C +100</div>
              <div className="phase__punkt">- Listing Islam token - KVAR-I</div>
              <div className="phase__punkt">- Holders KVAR-I +100</div>
              <div className="phase__punkt">- Listing Jew token - KVAR-J</div>
              <div className="phase__punkt">- Holders KVAR-J +100</div>
              <div className="phase__punkt diagramm">
                - Creating a dependency diagram of three tokens
              </div>
            </div>
          </div>

          <div className="road__ph">
            <div className="phase__one">Phase 2</div>
            <div className="phase__text">
              <div className="phase__punkt">- Holders +3000</div>
              <div className="phase__punkt">- Improve website</div>
              <div className="phase__punkt">- Add logos to tokens</div>
              <div className="phase__punkt">
                - The leading token receives a +2% increase in advertising based
                on the results of the previous month
              </div>
            </div>
          </div>

          <div className="road__ph">
            <div className="phase__one">Phase 3</div>
            <div className="phase__text">
              <div className="phase__punkt">- Improve RoadMap</div>
              <div className="phase__punkt">
                - Marcet Cap KVAR more 10,000,000 $
              </div>
              <div className="phase__punkt">- Holders more than 1000 total</div>
              
              <div className="phase__punkt">- Media Release at Bloomberg</div>
              <div className="phase__punkt">- Release the plan for the next 5 years</div>
            </div>
          </div>
          
        </div>
      </div>
      <div className="road__img-torus"><img src={torus} alt="KVAR token" /></div>
      <div className="road__img-rectangle"><img src={rectangle} alt="KVAR token" /></div>
      <div className="road__img-rectangle2"><img src={rectangl} alt="KVAR token" /></div>
    </div>
  );
};

export default RoadMap;
