export const objLinks = [
  {
    name: "About",
    link: '#about',
  },
  {
    name: "Tokens",
    link: '#three',
  },
  {
    name: "Roadmap",
    link: '#roadmap',
  },
  {
    name: "Tokenomics",
    link: '#tekonomiks',
  },
  {
    name: "How to Buy",
    link: '#how-to-buy',
  },
];
