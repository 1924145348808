import React, { useEffect, useState } from "react";
import First from "../../Boxes/First";
import Invest from "../../Boxes/Invest";
import Three from "../../Boxes/Three";
import Teconomic from "../../Boxes/Teconomic";
import RoadMap from "../../Boxes/RoadMap";
import ThreeTokens from "../../Boxes/ThreeTokens";
import AboutTokens from "../../Boxes/AboutTokens";
import HowBuy from "../../Boxes/HowBuy";
import BackToUp from "../../backToUp/BackToUp";
import Footer from "../../Footer/Footer";
import Preloader from "../../Boxes/Preloader";
import $ from "jquery";

const Lending = () => {
  const [timeOut, setTimeOut] = useState(true);

  useEffect(() => {
    if (timeOut) {
      setTimeout(() => {
        $("#preloader-active").css("display", "none");
        setTimeOut(false);
      }, 4000);
    }
  }, [timeOut]);

  return timeOut ? (
    <Preloader />
  ) : (
    <>
      <First />
      <Invest />
      <Three />
      <ThreeTokens />
      <AboutTokens />
      <Teconomic />
      <RoadMap />
      <HowBuy />
      <Footer />
      <BackToUp />
    </>
  );
};

export default Lending;
