import React from "react";
import { NavLink } from "react-router-dom";
import "../Button/buttonBuy.scss";

const ButtonBuy = () => {
  return (
    <>
      <NavLink to="https://app.uniswap.org/#/swap" className="button__buy" target="_blank">
        Buy token
      </NavLink>
    </>
  );
};

export default ButtonBuy;
