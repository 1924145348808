import React from "react";
import "../scss/invest.scss";

const Invest = () => {
  return (
    <div className="invest">
      <div className="invest__box">
        <div className="invest__main">
          <h2>Invest in your faith and spread it</h2>
        </div>
        <div className="invest__deskription">
          <div className="invest__major">
            The KVAR project invites investors to take part in a competition
            between three major world faiths
          </div>
          <div className="invest__major">
            Each of these faiths is represented by its own token:
          </div>
          <div className="invest__c">Christian - KVAR-C</div>
          <div className="invest__c">Islam - KVAR-I</div>
          <div className="invest__c">Jew - KVAR-J</div>
        
         
        </div>
      </div>
    </div>
  );
};

export default Invest;
