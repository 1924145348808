import React, { useEffect, useState } from 'react';
import { objTokens } from '../data/TokensData';
import { stateNameReligion } from '../slice/slice';
import '../scss/three-tokens.scss';
import { useDispatch } from 'react-redux';
import ButtonBuy from '../Button/ButtonBuy';
import ButtonBuyNo from '../Button/ButtonBuyNo';
import ImgLogo from './ImgLogo';
import vector from '../img_site/arrow.png';
import check from '../image/check.png';
import copy from '../image/copy.png';
import $ from 'jquery';
import Social from '../components/Social/Social';

const ThreeTokens = () => {
  const dispatch = useDispatch();
  const [ibBox, setIdBox] = useState('');
  const [contract, setContract] = useState('');

  useEffect(() => {
    if (contract) {
      $('.img__copy').css('display', 'none');
      $('.img__copy__check').css('display', 'block');
      $('#copy__active').css('display', 'block');
      $('#copy__active').css('display', 'flex');

      navigator.clipboard.writeText(contract);

      setTimeout(() => {
        $('#copy__active').css('display', 'none');
        $('.img__copy').css('display', 'block');
        $('.img__copy__check').css('display', 'none');
      }, 2000);
    }
    setContract('');
  }, [contract]);

  return (
    <div className="three">
      <div id="copy__active">
        <div className="copy__box"></div>
        <div>{ibBox} adress copied</div>
      </div>
      <div className="three__box">
        <div className="three__katalog" id="three">
          Tokens catalogue
        </div>
        <div className="three__tokens" id="tokens-try">
          {objTokens.map((items, index) => (
            <div className="tok__one" key={index}>
              <div className="tok__religion">{items.religion}</div>
              <div className="tok__logo">
                <img src={items.img} alt={items.alt} className="img__tok" />
                
              </div>
              <div className="tok__name">
                
                 <ImgLogo logo_t={items.logo_t} alt={items.alt} id={items.id} />
                
                <div className="tok__name__name">{items.name}</div>
              </div>
              
              <div className="tok__logo__small"></div>
              <div className="tok__line">
                <div
                  className="tok__character"
                  onClick={() => (
                    setIdBox(items.ib__box), setContract(items.contract)
                  )}
                >
                  {items.contract.length > 0 ? (
                    <>
                      <div className="tok__about contract" title="Copy adress">
                        {items.contract_short}
                      </div>
                      <div
                        className="tok__namber"
                        onClick={() => (
                          setIdBox(items.ib__box), setContract(items.contract)
                        )}
                      >
                        <img
                          src={copy}
                          alt="KVAR token"
                          className="img__copy"
                          title={items.alt}
                        />
                        <img
                          src={check}
                          alt="KVAR token"
                          className="img__copy__check"
                          title={items.alt}
                        />
                      </div>
                    </>
                  ) : (
                    <div className="tok__about contract ampty">
                      Smart contract not released
                    </div>
                  )}
                </div>
                <div className="tok__character">
                  <div className="tok__about">Price </div>
                  <div className="tok__namber">{items.price}</div>
                </div>
                <div className="tok__character">
                  <div className="tok__about">Holders</div>
                  <div className="tok__namber">{items.quantity}</div>
                </div>
                <div className="tok__character">
                  <div className="tok__about">Tokens Burned</div>
                  <div className="tok__namber">{items.burned}</div>
                </div>
                <div className="tok__character soc">
                  <Social
                    twitter={items.twitter}
                    title_twitter={items.title_twitter}
                    telegram={items.telegram}
                    title_telegram={items.title_telegram}
                    bscscan={items.bscscan}
                  />
                </div>
                <div className="tok__buttons">
                  {items.contract.length > 0 ? <ButtonBuy /> : <ButtonBuyNo />}
                  <div className="button__learn">
                    <div className="button__learn__text">
                      <a
                        href="#abouttok"
                        onClick={() =>
                          dispatch(stateNameReligion(items.ib__box))
                        }
                      >
                        Learn more
                      </a>
                    </div>
                    <img
                      src={vector}
                      alt="KVAR token"
                      className="img__more__arrow"
                    />
                  </div>
                  
                </div>
                
              </div>
            </div>
          ))}
        </div>
      </div>
      
    </div>
  );
};

export default ThreeTokens;
