import React from "react";
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Lending from "./components/Lending/Lending";
import Sidebar from "./Sidebar/Sidebar";
import "./App.scss";
import ShowLogo from "./Boxes/ShowLogo";

function App() {
  return (
    <BrowserRouter>
      <div className="wrapper">
        <Routes>
          <Route path="/" element={<Lending />} />
          <Route path="logo/:id" element={<ShowLogo />} />
        </Routes>
        <Sidebar />
      </div>
    </BrowserRouter>
  );
}

export default App;
