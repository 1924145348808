import React, { useEffect } from "react";
import { FaBars } from "react-icons/fa";
import "./header.scss";
import { useDispatch, useSelector } from "react-redux";
import { stateSideBar } from "../../slice/slice";
import { objLinks } from "../../data/LinksHeaderDate";
import $ from "jquery";

const Navigation = () => {
  const openSide = useSelector((state) => state.cur.side);
  const dispatch = useDispatch();

  useEffect(() => {
    if (openSide) {      
      $("#sidebar").css("display", "block");
      $("body").css("overflowY", "hidden");

      document.addEventListener("click", (event) => {
        const target = event.target;

        if (target.className === "left__side") {
          dispatch(stateSideBar(false));
        }
      });
    }
  }, [openSide]);

  return (
    <nav className="navigation">
      <div
        style={{
          fontSize: "21px",
          color: "white",
        }}
        className="favbar"
        onClick={() => dispatch(stateSideBar(true))}
      >
        <FaBars />
      </div>
      {objLinks.map((items, index) => (
        <div className="nav__link" key={index}>
          <div className="nav__item_a">
            <a href={items.link}>{items.name}</a>
          </div>
        </div>
      ))}
    </nav>
  );
};

export default Navigation;
