import React from 'react';
import { useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { stateIdForImgPage } from '../slice/slice';

const ImgLogo = ({ logo_t, alt, id }) => {
  const dispatch = useDispatch();

  return (
    <NavLink to={`logo/${id}`} title={alt} target="_blank" rel="noreferrer">
      <div className="logo_img_token">
        <img
          src={logo_t}
          alt={alt}
          className=""
        />
      </div>
    </NavLink>
  );
};

export default ImgLogo;
