import { styled } from '@mui/system';

export const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '75%',
  [theme.breakpoints.down('desktop')]: {
    width: '80%',
  },
  [theme.breakpoints.down('laptop')]: {
    width: '85%',
  },
  [theme.breakpoints.down('tablet')]: {
    width: '92%',
  },
  [theme.breakpoints.down('mobile')]: {
    width: '97%',
  },
}));

export const Power = styled('div')(({ theme }) => ({
  textAlign: 'center',
  color: 'white',
  margin: '15px 0',
  fontWeight: '600',
  letterSpacing: '0.05rem',
  fontSize: '30px',

  [theme.breakpoints.down('desktop')]: {
    fontSize: '28px',
  },
  [theme.breakpoints.down('laptop')]: {
    fontSize: '24px',
  },
  [theme.breakpoints.down('tablet')]: {
    fontSize: '22px',
  },
  [theme.breakpoints.down('mobile')]: {
    fontSize: '18px',
  },
}));
