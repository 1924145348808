import React from 'react';
import { objTokens } from '../data/TokensData';
import '../scss/about-tokens.scss';
import { useSelector } from 'react-redux';
import { stateNameReligion } from '../slice/slice';
import { useDispatch } from 'react-redux';
import ButtonBuy from '../Button/ButtonBuy';
import ButtonBuyNo from '../Button/ButtonBuyNo';

const AboutTokens = () => {
  const curr = useSelector((state) => state.cur.status);
  const dispatch = useDispatch();

  return (
    <div className="about__tok">
      <div className="about__tok__box">
        <div className="name__token__link" id="abouttok">
          {objTokens.map((items, index) => (
            <div
              className="id__box"
              key={index}
              style={{
                color:
                  curr.length === 0 && items.ib__box === 'Christian' && 'black',
                borderBottom:
                  curr.length !== 0 &&
                  items.ib__box === curr &&
                  '2px solid #867a7a',
              }}
              onClick={() => dispatch(stateNameReligion(items.ib__box))}
            >
              {items.ib__box}
            </div>
          ))}
        </div>
        <>
          {objTokens.map(
            (item, index) =>
              curr === item.ib__box && (
                <div className="about__three__tokens" key={index} id="below">
                  <div className="about__img">
                    <img src={item.img} alt={item.alt} />
                  </div>
                  <div className="box__text">
                    <div className="description">
                      <div className="kvar">{item.name} token</div>
                      <div className="description_token">
                        {item.description_tok}
                      </div>
                      <div className="short__about">{item.about_faith}</div>
                    </div>
                    <div className="buy">
                      {item.contract.length > 0 ? (
                        <ButtonBuy />
                      ) : (
                        <ButtonBuyNo />
                      )}
                    </div>
                  </div>
                </div>
              )
          )}
          {objTokens.map(
            (item, index) =>
              item.ib__box === 'Christian' &&
              curr.length === 0 && (
                <div className="about__three__tokens" key={index}>
                  <div className="about__img">
                    <img src={item.img} alt={item.alt} />
                  </div>
                  <div className="box__text">
                    <div className="description">
                      <div className="kvar">{item.name} token</div>
                      <div className="description_token">
                        {item.description_tok}
                      </div>
                      <div className="short__about">{item.about_faith}</div>
                    </div>
                    <div className="buy">
                      {' '}
                      <ButtonBuy />
                    </div>
                  </div>
                </div>
              )
          )}
        </>
      </div>
    </div>
  );
};

export default AboutTokens;
