import React from 'react';
import './social.scss';
import bscscanimg from './images/bscscan.png';

const Social = ({ twitter, title_twitter, telegram, title_telegram, bscscan }) => {
  return (
    <div className="social">
      <a
        href={twitter}
        className="twitter"
        title={title_twitter}
        target="_blank"
        rel="noreferrer"
      ></a>

      <a
        href={telegram}
        className="telegram"
        title={title_telegram}
        target="_blank"
        rel="noreferrer"
      >
        {' '}
      </a>
      {bscscan.length > 0 &&
      (
        <a
          href={bscscan}
          className="bscscan"
          title="bscScan"
          target="_blank"
          rel="noreferrer"
        >
          <img src={bscscanimg} alt="KVAR token" title="bscScan" />
        </a>
      )}
    </div>
  );
};

export default Social;
