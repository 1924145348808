import React, { useEffect } from "react";
import closeimg from "../img_site/close.png";
import "../Sidebar/sidebar.scss";
import { useDispatch, useSelector } from "react-redux";
import { stateSideBar } from "../slice/slice";
import { objLinks } from "../data/LinksHeaderDate";
import $ from "jquery";

const Sidebar = () => {
  const closeSide = useSelector((state) => state.cur.side);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!closeSide) {
      $("#sidebar").css("display", "none");
      $("body").css("overflowY", "scroll");
    }
  }, [closeSide]);

  return (
    <div className="sidebar" id="sidebar">
      <div className="sidebar__box">
        <div className="left__side"></div>

        <div className="right__side">
          <div
            className="right__close"
            onClick={() => dispatch(stateSideBar(false))}
          >
            <img src={closeimg} alt="KVAR token" />
          </div>
          <div className="right__side__links">
            {objLinks.map((items, index) => (
              <div
                className="nav__mob"
                key={index}
                onClick={() => dispatch(stateSideBar(false))}
              >
                <a href={items.link}>{items.name}</a>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
