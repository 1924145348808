import React from 'react';
import '../scss/howToBuy.scss';
import squareb from '../img_site/square-blue.png';
import squareg from '../img_site/square-ginger.png';

const HowBuy = () => {
  return (
    <div className="how" id="how-to-buy">
      <div className="how__box">
        <div className="how__to__buy">How to Buy a tokens</div>
        <div className="how__here"></div>
        <div className="how__step">
          <div className="how__step_box">
            <div className="step__text">
              <div className="step__one">
                <div className="one__number">1</div>
                <div className="one__text">
                  <div className="one__text__name">Create Wallet</div>
                  <div className="one__text__desk">
                    Install Metamask or Trustwallet on your desktop or mobile
                    device. Your wallet will let you buy, sell, transfer, or
                    receive $KVAR-C or $KVAR-I or $KVAR-J
                  </div>
                </div>
              </div>
            </div>
            <div className="step__text">
              <div className="step__one">
                <div className="one__number">2</div>
                <div className="one__text">
                  <div className="one__text__name">Send BNB to Your Wallet</div>
                  <div className="one__text__desk">
                    Buy BNB on any cryptocurrency exchange of your liking and
                    transfer your BNB to your wallet
                  </div>
                </div>
              </div>
            </div>
            <div className="step__text">
              <div className="step__one">
                <div className="one__number">3</div>
                <div className="one__text">
                  <div className="one__text__name">
                    Connect Wallet to the Voltichange widget (or Uniswap)
                  </div>
                  <div className="one__text__desk">
                    If KVAR isn't already listed, click on “select token”, paste
                    the KVAR contract address, and select KVAR.
                  </div>
                </div>
              </div>
            </div>
            <div className="step__text">
              <div className="step__one">
                <div className="one__number">4</div>
                <div className="one__text">
                  <div className="one__text__name">Swap BNB for KVAR</div>
                  <div className="one__text__desk">
                    Now go ahead and proceed with the swap. Your KVAR-C or KVAR-I
                    or KVAR-J tokens will be added to your wallet. (If you can’t
                    yet see your new tokens on Metamask, click on “import
                    tokens” - “custom token” and paste the KVAR-C or KVAR-I
                    or KVAR-J contract address)
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="step__widget">


          </div>
        </div>
      </div>
      <div className="how__img-squareb">
        <img src={squareb} alt="KVAR token" />
      </div>
      <div className="how__img-squareg">
        <img src={squareg} alt="KVAR token" />
      </div>
    </div>
  );
};

export default HowBuy;
