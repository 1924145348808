import React from "react";
import { NavLink } from "react-router-dom";
import "../Button/buttonBuy.scss";

const ButtonBuy = () => {
  return (
    <>
      <NavLink to="/" className="button__buy__no" >
        Buy token
      </NavLink>
    </>
  );
};

export default ButtonBuy;
