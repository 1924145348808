export const objTokens = [
  {
    name: 'KVAR-C',
    img: require('../img_tok/kvarc.svg').default,
    logo_t: require('../image/kvar-c-50.png'),
    logo_t_big: require('../image/kvar-c-120.png'),
    alt: 'Copy KVAR-C adress',
    altLogo: 'KVAR-C token',
    religion: 'Christian',
    id: 1,
    capitalization: '0',
    price: '0.001',
    burned: '0',
    quantity: '0',
    contract: '2222',
    contract_short: '0xlcsd...sdf9790v6',
    id_link: '#Christian',
    ib__box: 'Christian',
    description_tok:
      ' Is a token that represents the Christian faith. By purchasing it, you express a preference for the Christian belief. You would like it to spread to more and more people, and for more individuals to learn about Jesus the Savior.',
    about_faith:
      'Christians are followers of Christ, believing in Jesus Christ as the Savior and Son of God. They profess the Christian faith based on the Gospel and the Bible. Christians gather in churches for worship, prayer, worship services, and fellowship with other believers. They seek to glorify Jesus and serve others in all areas of life.',
    twitter: 'https://twitter.com/KVAR_C?t=AhbyOSjssZhRGXqWGww6-w&s=35',
    title_twitter: 'twitter KVAR-C',
    telegram: 'https://t.me/kvar_c',
    title_telegram: 'Telegram KVAR-C',
    bscscan:
      'https://bscscan.com/token/0x6120ba0b3538e40aa7aac32558e5dd0737b7ac90',
    title_bscscan: 'bscscan KVAR-C',
  },
  {
    name: 'KVAR-I',
    img: require('../img_tok/kvari.svg').default,
    logo_t: require('../image/kvar-i-50.png'),
    logo_t_big: require('../image/kvar-i-120.png'),
    alt: 'Copy KVAR-I adress',
    altLogo: 'KVAR-I token',
    religion: 'Islam',
    id: 2,
    capitalization: '0',
    price: '0',
    quantity: '0',
    contract: '',
    contract_short: '',
    id_link: '#Islam',
    ib__box: 'Islam',
    description_tok:
      ' Is a token that represents the Islamic faith. By purchasing it, you express a preference for the Islam faith. You would like it to spread to more and more people, and for more individuals to learn about the Prophet Muhammad',
    about_faith:
      'Muslims are followers of the religion of Islam, believing in Allah as the one God and in the teachings of the Prophet Muhammad. They adhere to the faith in the Quran, the holy book of Islam, believed to be the word of Allah. Muslims observe the five pillars of Islam, which include the declaration of faith, prayer, fasting, giving alms, and pilgrimage to Mecca. They strive to live by principles of morality, justice, and compassion, and advocate for peace and harmony',
    twitter: 'https://twitter.com/KVAR_I?t=4GfvvNDcKETOhjdzFQkgPQ&s=35',
    title_twitter: 'twitter KVAR-I',
    telegram: 'https://t.me/kvar_i',
    title_telegram: 'Telegram KVAR-I',
    bscscan: '',
    title_bscscan: 'bscscan KVAR-I',
  },
  {
    name: 'KVAR-J',
    img: require('../img_tok/kvarj.svg').default,
    logo_t: require('../image/kvar-j-50.png'),
    logo_t_big: require('../image/kvar-j-120.png'),
    alt: 'Copy KVAR-J adress',
    altLogo: 'KVAR-J token',
    religion: 'Jewish',
    id: 3,
    capitalization: '0',
    price: '0',
    quantity: '0',
    contract: '',
    contract_short: '',
    id_link: '#Jew',
    ib__box: 'Jew',
    description_tok:
      ' Is a token that represents the Jewish faith. By purchasing it, you express a preference for the Jewish faith. You are also focused on preserving and transmitting your faith and culture among the Jewish people.',
    about_faith:
      'Jews are members of the Jewish people. They are followers of Judaism, believing in the one God of Abraham, Isaac, and Jacob, and adhering to the Ten Commandments as outlined in the Torah of Moses. Jews have a rich heritage that includes events such as the Great Flood, the Exodus from Egypt, and the construction of the Temple in Jerusalem. Judaism anticipates the coming of the Messiah.',
    twitter: 'https://twitter.com/KVAR_J?t=JQSdAMecDx3E-PAT3BCVRA&s=35',
    title_twitter: 'twitter KVAR-J',
    telegram: 'https://t.me/kvar_j',
    title_telegram: 'Telegram KVAR-J',
    bscscan: '',
    title_bscscan: 'bscscan KVAR-J',
  },
];
