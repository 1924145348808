import React from 'react';
import { useParams } from 'react-router-dom';
import { objTokens } from '../data/TokensData';
import '../scss/showLogo.scss';

const ShowLogo = () => {
  const { id } = useParams();

  return (
    <>
      {objTokens.map((item, index) => (
        <>
          {item.id == id && (
            <div className="show__logo" key={index}>
              <img src={item.logo_t_big} alt={item.altLogo} className="" />
              <div className="show__alt">{item.altLogo}</div> 
            </div>
          )}
        </>
      ))}
    </>
  );
};

export default ShowLogo;
