import React from "react";
import "../scss/preloader.scss";

const Preloader = () => {
  return (
    <div id="preloader-active">
      <div className="preloader">
        <div className="preloader-inner position-relative">
          <div className="text-center">
            <div className="loader"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Preloader;
