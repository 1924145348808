import React from 'react';
import Header from '../components/heder/Header';
import { ThemeProvider } from '@mui/system';
import { theme } from '../theme/themeConteiner';
import '../scss/first.scss';
import { Container, Power } from '../BreakPoints/FirstBp';
import chart from '../image/chart.png';
import vector from '../img_site/arrow.png';

const First = () => {
  return (
    <ThemeProvider theme={theme}>
      <div className="first">
        <Container>
          <Header />
          <div className="text__power">
            <div className="first_text">
              <div className="feiht__true">
                Is your faith true? Is your faith more scaled?
              </div>
              <Power>
                <h1>KVAR - the World Religion Indicator</h1>
              </Power>
              <div className="part">
                <div className="participal">
                  <div className="participal__text">
                    Show your power of faith to the whole world. Become the part
                    of something great. Prove other people that your God is the
                    greatest.
                  </div>
                  <div className="participal__box">
                    <a href="#three" className="participal__button">
                      Become a participat
                    </a>
                    <div className="participal__learn__more">
                      <a href="#tekonomiks" className="learn">
                        Tokenomics
                      </a>
                      <img
                        src={vector}
                        alt="KVAR token"
                        className="learn__arrow"
                      />
                    </div>
                  </div>
                </div>

                <div className="first-img">
                  <img
                    src={chart}
                    alt="KVAR Indicator the chart"
                    className="chart-img"
                  />
                </div>
              </div>
            </div>
          </div>
        </Container>
        <div className="first-img-bg">
          <img
            src={chart}
            alt="KVAR Indicator the chart"
            className="chart-img"
          />
        </div>
      </div>
    </ThemeProvider>
  );
};

export default First;
