import React from 'react';
import { NavLink } from 'react-router-dom';
import './header.scss';

const Logo = () => {
  return (
    <NavLink to="/" className="logo__name">
      <div className="logo"></div>
      <div className="company">KVAR Indicator</div>
    </NavLink>
  );
};

export default Logo;
