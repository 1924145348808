import React from "react";
import "../scss/three.scss";

const Three = () => {
  return (
    <div className="two" id="about">
      <div className="two__owner">
        <div className="two__about">About the event</div>
        <div className="two__faiht">
          <div className="two__faiht__true">Whose faith is true?</div>
          <div className="two__faiht__text">
            Tokens are not tied to fiat, gold or a person, but only are affected
            by the strength of your faith. Over the next 10-15 years, the most
            capitalized token will show us which faith humanity will come to in
            the next 100-200 years. More invested – more widespreaded
          </div>
        </div>

        <div className="two__tokens">
          
          <div className="one__box">
            <div className="one__box_teams__one">1 winner</div>
            <div className="one__box_text__one">
            Over the next 5 years, the KVAR indicator project will show the direction in which the world's religions are evolving. The diagram will indicate which religion possesses the greatest prosperity.
            </div>
          </div>

          <div className="one__box">
            <div className="one__box_teams">3 teams</div>
            <div className="one__box_text">
              Eternal confrontation: Christians, Muslims, and Jews. Each of
              these is represented by its own token. Show the world the power of
              faith
            </div>
          </div>

          <div className="one__box">
            <div className="one__box_teams">3 tokens</div>
            <div className="one__box_text">
              Investment is based on your faith. The project offers the
              opportunity to influence the spread of our faith in the investment
              horizon
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Three;
